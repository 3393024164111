html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Geomanist Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

#filter_input:placeholder-shown,
.filter_input:placeholder-shown {
  background-image: url('./icons-search-gray.svg');
  background-size: 16px 16px !important;
  background-repeat: no-repeat !important;
  background-Position-y: center !important;
  background-Position-x: 99% !important;
  padding-right: 32px;
  margin-right: -32px;
}

#filter_input:not(:placeholder-shown),
.filter_input:not(:placeholder-shown) {
  background-image: none;
}

#meetingSDKElement {
  top: 59px !important;
  left: 0 !important;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 244px;
  /* width of speaker and ribbon view */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  height: 26px !important;
}

.MuiAutocomplete-input {
  padding: 0px !important
}

.MuiDialog-paper {
  overflow: visible !important;
}

.MuiDialogContent-root {
  overflow: visible !important;
}