.dotsWrapper {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dot {
  animation: loading 0.8s ease-in-out infinite;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #2b3b4f;
  border: solid thick white;
  border-radius: 50%;
  position: relative;
}

.dot1 {
  animation-delay: 0.1s;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.3s;
}

@keyframes loading {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
}


.loginContainer {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  max-height: 1080px;
  margin: 0px;
  border-spacing: 0px;
  margin: auto;
  border: solid thin rgba(0, 0, 0, 0.05);
}

.loginImageSection {
  max-width: 0px;
  overflow: hidden;
}

.loginBackgroundImage {
  margin-left: calc((1920px - 100% - 546px) * -1 / 1.85);
  position: relative;
  background: url('./images/brainq_login_background.svg');
  background-size: cover;
  width: 1374px;
  height: 100%;
  max-height: 1080px;
}

.brainqLogoWhite {
  position: absolute;
  background: url('./images/brainq_logo_white.svg');
  width: 222px;
  height: 54px;
  margin: 281px 576px;
  filter: drop-shadow(3px 3px 15px rgba(0, 0, 0, .1));
}

.loginFormSection {
  width: 546px;
  position: relative;
}

.loginFormContainer {
  margin: 32px
}


.loginTitleSection {
  margin-bottom: 24px;
}

.loginPageTitle {
  font-size: 22px;
}

.loginPageDescription {
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.14;
  color: #2b3b4f;
}

.inputContainer {
  transition: all 0.2s;
  width: 100%;
  height: 56px;
  border-spacing: 0px;
  border-radius: 3px;
  border: solid 1px rgba(193, 201, 206, 0.3);
  margin-bottom: 16px;
}

.error {
  border-color: #ff4a00;
}

.inputContainer:hover {
  opacity: 1;
  border: solid 1px rgba(43, 59, 79, 0.4);
}

.inputContainer:focus-within {
  opacity: 1;
}

input {
  border: none;
  display: inline;
  vertical-align: middle;
  line-height: 56px;
  width: 100%;
  font-size: 14px;
  background-color: transparent;
  color: #2b3b4f;
}

input::placeholder {
  color: #adb6bb;
}

input:focus {
  outline: none;
}

.inputIcon {
  max-width: 32px;
  padding: 16px;
  opacity: 0.2;
}

.inputIcon:hover,
.inputIcon:focus {
  transition: all 0.2s;
  opacity: 1;
}

.inputField {
  width: 100%;
  overflow: hidden;
}

.emailIcon {
  width: 24px;
  height: 24px;
  background: url('./images/icon_email.svg');
}

.passwordIcon {
  width: 24px;
  height: 24px;
  background: url('./images/icon_lock.svg');
}

.passwordRevealIcon {
  width: 24px;
  height: 24px;
  background: url('./images/icon_see.svg');
  cursor: pointer;
}

.passwordRevealIcon:active:hover {
  background: url('./images/icon_hide.svg');
}

.belowInputsSection {
  position: relative;
  border-spacing: 0px;
}

.backToLogin {
  background: url('./images/icon_arrow_left.svg');
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding-left: 20px;
  padding-top: 2px;
  margin-bottom: 40px;
  height: 16px;
  font-size: 14px;
  line-height: 1.14;
  color: #2b3b4f;
  cursor: pointer;
}

.forgotPasswordLink,
.errorMessage {
  width: 80%;
  height: 14px;
  margin-top: 0px;
  opacity: 0.5;
  font-size: 12px;
  position: absolute;
  color: #2b3b4f;
  cursor: pointer;
}

.forgotPasswordLink {
  left: 0px;
}

.errorMessage {
  color: #ff4a00;
  text-align: end;
  right: 0px;
  white-space: pre-wrap;
}

.loginPageButton {
  width: 100%;
  height: 56px;
  padding: 16px 40px;
  font-size: 22px;
  border-radius: 10px;
  background-color: #2b3b4f;
  line-height: 24px;
  font-size: 16px;
  color: white;
  font-weight: 500;
  margin-top: 56px;
  cursor: pointer;
}

.waitCover {
  position: absolute;
  background-color: white;
  opacity: 0.4;
  left: calc(100% - 548px);
  right: 0px;
  top: 1px;
  height: 1080px;
}

.nonVerifiedPasswordCeriteria {
  text-decoration: underline;
}

.verifiedPasswordCeriteria {
  color: green;
  font-weight: bold;
}